import React from "react";
import "./svg.css";

const SVG = () => {
  return (
    <svg
      width="100%"
      height="100%"
      id="svg"
      viewBox="0 0 1440 590"
      xmlns="http://www.w3.org/2000/svg"
      className="transition duration-300 ease-in-out delay-150"
      style={{
        zIndex: "-1",
        position: "relative",
        marginBottom: '-30%'
      }}
    >
      <path
        d="M 0,600 C 0,600 0,240 0,240 C 50.191589591811564,226.46161055617216 100.38317918362313,212.9232211123443 156,203 C 211.61682081637687,193.0767788876557 272.65887285731907,186.76872610679487 328,208 C 383.34112714268093,229.23127389320513 432.9813293871007,278.001874460476 484,278 C 535.0186706128993,277.998125539524 587.415809594278,229.223776051301 649,226 C 710.584190405722,222.776223948699 781.3554322357874,265.1030213343199 828,268 C 874.6445677642126,270.8969786656801 897.1624614625723,234.3641386114194 947,227 C 996.8375385374277,219.6358613885806 1073.9947219139228,241.4404242200025 1132,256 C 1190.0052780860772,270.5595757799975 1228.8586508817364,277.8741645085707 1277,274 C 1325.1413491182636,270.1258354914293 1382.570674559132,255.06291774571466 1440,240 C 1440,240 1440,600 1440,600 Z"
        stroke="none"
        stroke-width="0"
        fill="#212529"
        fill-opacity="0.4"
        className="transition-all duration-300 ease-in-out delay-150 path-1"
        transform="rotate(-180 720 300)"
      ></path>
      <path
        d="M 0,600 C 0,600 0,360 0,360 C 42.20742385004317,361.116142557652 84.41484770008634,362.23228511530397 141,367 C 197.58515229991366,371.76771488469603 268.54803304969784,380.18700209643606 325,368 C 381.45196695030216,355.81299790356394 423.39302010112215,323.01970649895185 469,315 C 514.6069798988779,306.98029350104815 563.8798865458133,323.7341719077568 620,338 C 676.1201134541867,352.2658280922432 739.0874337156247,364.043605870021 799,363 C 858.9125662843753,361.956394129979 915.7703785916881,348.0914046121593 974,335 C 1032.229621408312,321.9085953878407 1091.8310519176225,309.5907756813417 1136,318 C 1180.1689480823775,326.4092243186583 1208.905413737822,355.5454926624738 1257,366 C 1305.094586262178,376.4545073375262 1372.547293131089,368.22725366876307 1440,360 C 1440,360 1440,600 1440,600 Z"
        stroke="none"
        stroke-width="0"
        fill="#212529"
        fill-opacity="0.53"
        className="transition-all duration-300 ease-in-out delay-150 path-2"
        transform="rotate(-180 720 300)"
      ></path>

      <path
        d="M 0,600 C 0,600 0,480 0,480 C 52.53634233567641,465.58386977432485 105.07268467135282,451.16773954864965 167,459 C 228.92731532864718,466.83226045135035 300.24560365026514,496.91291157972614 346,515 C 391.75439634973486,533.0870884202739 411.9449007275866,539.1806141324455 462,520 C 512.0550992724134,500.8193858675545 591.9747934393883,456.3646318904921 659,452 C 726.0252065606117,447.6353681095079 780.1559255148601,483.3608583055864 818,494 C 855.8440744851399,504.6391416944136 877.4015045011714,490.1919348871624 933,473 C 988.5984954988286,455.8080651128376 1078.238056480454,435.87140214576397 1139,449 C 1199.761943519546,462.12859785423603 1231.6462695770133,508.3224565297817 1277,519 C 1322.3537304229867,529.6775434702183 1381.1768652114934,504.83877173510916 1440,480 C 1440,480 1440,600 1440,600 Z"
        stroke="none"
        stroke-width="0"
        fill="#212529"
        fill-opacity="1"
        className="transition-all duration-300 ease-in-out delay-150 path-3"
        transform="rotate(-180 720 300)"
      ></path>
    </svg>
  );
};

export default SVG;
